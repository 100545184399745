import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers";

const API_URL = Global.url;

class AuthService {
    signIn(body) {
        let ruta = `${API_URL}/login`;
        return axios
            .post(ruta, body)
            .catch((error) => {
                return error.response;
            });
    }

    signOut(body) {
        let ruta = `${API_URL}/logout`;
        return axios
            .post(ruta, body)
            .catch((error) => {
                return error.response;
            });
    }

    async updatePassword(body) {
        const ruta = `${API_URL}/updatePassword`;
        return axios.put(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new AuthService();