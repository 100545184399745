import React from 'react';
import { CardHeader, CardContent, CardActions, } from '@mui/material';
import MofService from '../../../../services/mof';
import { Modal } from 'antd';
import { Toast } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/responseCodes';
import { Box } from '@mui/system';
import AddRegisterMofsV2 from '../../../components/MofV2/AddMofs/AddRegisterMofsV2';
import Swal from 'sweetalert2';

const AddRegisterMof = (props) => {
  const { userToken,getAprobado,open, handleClose, listarMof, setOpenModalLoader, puesto, companies, datosMof,
    setDatosMof, puestoId, setPuestoId, companieId, setCompanieId, clienteId, setClienteId
    , mofId, idUsuario,setMofId, opcionVer, mofxUsuarioBody,idPerfil,personaAprobacion,setPersonaAprobacion} = props;
 
  const cleanInfo = () => {
    setPuestoId(null);
    setMofId(null);
    setCompanieId(null);
    setClienteId(null);
    setDatosMof([]);
    handleClose();
    setPersonaAprobacion(null);
  }
  const guardado = async (e) => {
    e.preventDefault();
    if (puestoId === null || puestoId === "" || clienteId === null || clienteId === "") {
      Toast.fire({
        icon: 'warning',
        title: 'Falta Puesto o Cliente'
      });
      return;
    }

    // Mostrar alerta de confirmación
    const result = await Swal.fire({
      title: '¿Deseas guardar?',
      html: "Asegúrate de que todos los datos son correctos.<br><br>",
      icon: 'warning',

      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, guardar',
      cancelButtonText: 'No, cancelar'
    });
    if (result.isConfirmed) {
      setOpenModalLoader(true);
    
      try {
        // Filtrar datosMof excluyendo los objetos con id_preguntamof 125 y 126
        const datosMofFiltrados = datosMof.filter(item => item.id_preguntamof !== 125 && item.id_preguntamof !== 126);
       

        // Verificar si algún objeto en datosMofFiltrados tiene detalle_mof igual a null
        const isDetalleMofNull = datosMofFiltrados.some(item => item.detalle_mof === null || item.detalle_mof === "");

        let estado=1;
        const result = await MofService.saveMof(datosMof, puestoId, clienteId, mofId, estado);
       
        if (result.status === CODES.SUCCESS_200) {
          Toast.fire({
            icon: 'success',
            title: result.data.message || 'Éxito en el registro Mof'
          });
          cleanInfo();
        } else {
          Toast.fire({
            icon: 'error',
            title: (result?.response?.data?.message || 'Error en el registro Mof')
          });
        }
        return [];
      } catch (error) {
        console.log(`Error en guardado: ${error}`);
      } finally {
        listarMof();
        setOpenModalLoader(false);
      }
    } else {
      Toast.fire({
        icon: 'info',
        title: 'Operación cancelada'
      });
    
      let html = "";
      for (let i = 0; i < datosMof.length; i++) {
        // Verifica si el detalle_mof está vacío y si el id_preguntamof no es 128, 129 o 130
        if (!datosMof[i]["detalle_mof"] && ![128, 129, 130,132,133,135,136].includes(datosMof[i]["id_preguntamof"])) {
          /*Toast.fire({
            icon: 'warning',
            title:  `Falta completar ${datosMof[i]["descripcion"]}`
          });*/
          html += `${datosMof[i]["descripcion"]?.toUpperCase()} <br/> `;
        }
      }
    
      Swal.fire({
        title: "Faltan completar estos campos",
        icon: "warning",
        html: html,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
      });
    }
  };


  return (
    <Modal
      title={
        <CardHeader
          title={"Registro de MOF"}
          subheader={"Informacion completa del mof"}
          sx={{
            paddingBottom: "0.3rem",
            paddingTop: 0,
            margin: "-0.5rem -1rem -0.1rem -1rem",
            borderBottom: `solid 0.05rem #D5DBDB`
          }}
        />
      }
      open={open}
      onOk={cleanInfo}
      onCancel={cleanInfo}
      destroyOnClose={true}
      footer={null}
      style={{ top: "10%" }}
      width={"90%"}
    >
      <Box sx={{ overflow: "scroll", p: 2, backgroundColor: "#" }}>
        <form onSubmit={guardado}>
          <CardContent
            sx={{
              padding: 0,
              margin: "0 -0.5rem", backgroundColor: ""
            }}
          >
            <AddRegisterMofsV2
              setOpenModalLoader={setOpenModalLoader}
              puesto={puesto} companies={companies}
              datosMof={datosMof} setDatosMof={setDatosMof}
              puestoId={puestoId} setPuestoId={setPuestoId}
              companieId={companieId} setCompanieId={setCompanieId}
              clienteId={clienteId} setClienteId={setClienteId}
              opcionVer={opcionVer} mofxUsuarioBody={mofxUsuarioBody}
              idPerfil={idPerfil} userToken={userToken}
              mofId={mofId} idUsuario={idUsuario} personaAprobacion={personaAprobacion} setPersonaAprobacion={setPersonaAprobacion} getAprobado={getAprobado}
              guardado={guardado}
             
            />
          </CardContent>
          <CardActions
            sx={{
              margin: "0.5rem -1rem -1rem -1rem",
              borderTop: `solid 0.05rem #00337C`, backgroundColor: ""
            }}
          >
            {opcionVer !== 5 && (
              <button
                type={"submit"}
                className="btn btn-primary btn-sm"
                style={{ fontSize: "15px" }}
              >
                Guardar
              </button>
            )}
            <button
              type={"button"}
              onClick={cleanInfo}
              className="btn btn-danger btn-sm"
              style={{ fontSize: "15px" }}
            >
              Cerrar
            </button>
            {/*(idPerfil === 16 || idPerfil ===1 ) && (
              <button
                type={"submit"}
               // onClick={guardado}
                className="btn btn-info btn-sm"
                style={{ fontSize: "15px" }}
              >
                Aprobar
              </button>
            )*/}
          </CardActions>
        </form>
      </Box>
    </Modal>
  );
};





export default AddRegisterMof;