import React, { useState, useEffect } from 'react';
import { Box, Grid, MenuItem, Typography, TextField } from '@mui/material';
import { Input, Select } from "antd";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SpinnerLoader from '../../components/general/SpinnerLoader';
import MainButton from '../../components/general/MainButton';
import { useTheme } from '@mui/material/styles';
import BodyLaptops from '../../components/Requests/BodyTabs/BodyLaptops';
import BodyCelulares from '../../components/Requests/BodyTabs/BodyCelulares';
import BodyRelojes from '../../components/Requests/BodyTabs/BodyRelojes';
import BodyOtros from '../../components/Requests/BodyTabs/BodyOtros';
import OrganizationalService from '../../../services/organizational';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import GeneralService from "../../../services/general";
const { Option } = Select;

const Requests = ({ userToken }) => {
    const url = "'" + window.location.pathname + "'";
    const persona_id = userToken?.user.persona_id;
    const solicitante = userToken?.user.trabajador;

    const [openModalLoader, setOpenModalLoader] = useState(true);
    const theme = useTheme();
    //********************* */
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [boss, setboss] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [ceco, setCeco] = useState(null);


    const [infoBoss, setInfoBoss] = useState(null);
    const [infoCompany, setInfoCompany] = useState(null);
    const [infoCeco, setInfoCeco] = useState(null);


    const [body, setBody] = useState(0); //0: body areas, 1: body turnos
    const handleChangeBody = (event, newValue) => {
        setBody(newValue);
    };
    //********************* */


    const filterOption = (input, option) => {
        if (option && typeof option.children === "object" && option.children) {
            return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        } else if (option && typeof option.children === "string") {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
    };

    useEffect(() => {
        init();
        getJefesTrabajador(); getEmpresas();
    }, [])

    const init = () => {
        setOpenModalLoader(false);
    }

    //*****************************************/

    const getEmpresas = async () => {
        await GeneralService.getEmpresas()
            .then((result) => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach((element) => {
                        element.id = element.id_empresa;
                        element.value = element.id_empresa;
                        element.label = element.desEmpresa;
                    });
                    setCompanies(result.data);
                } else {
                    Toast.fire({
                        icon: "info",
                        title: "No hay información de empresas",
                    });
                }
            })
            .catch((err) => {
                console.log("getEmpresas", err);
            });
    };


    const getJefesTrabajador = async () => {
        await OrganizationalService.getJefesTrabajador(url, persona_id)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    result.data?.forEach(element => {
                        element.id = element.id_jefe;
                        element.value = element.id_jefe;
                        element.label = element.nombreJefe;
                    })
                    setboss(result.data);
                } else {
                    Toast.fire({
                        icon: 'info',
                        title: 'No hay información de jefes'
                    })
                }
            })
            .catch(err => {
                console.log('getJefesTrabajador', err)
            })

    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box textAlign={'center'} mt={5}>
                <Typography variant="h4" gutterBottom color={'primary'}>
                    Solicitud de equipos TI
                </Typography>
            </Box>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={3}
            >
                <Grid item xs={12} sm={10} md={8} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    <>
                        <Grid container p={1}>
                            <Grid item md={6}>
                                <fieldset>
                                    <legend style={{ fontSize: "medium" }}>Empresas</legend>
                                    <Select
                                        style={{ width: "95%" }}
                                        size='large'

                                        filterOption={filterOption}
                                        showSearch
                                        value={infoCompany?.value || 0}
                                        onChange={(value, option) => {
                                            setInfoCompany(option);
                                        }}

                                    >
                                        <Option value={0}>
                                            <em>Seleccionar empresa</em>
                                        </Option >
                                        {companies?.map((opt) => {
                                            return (
                                                <Option key={"comp" + opt.id} value={opt.id}>
                                                    {opt.id + " - " + opt.label}
                                                </Option >
                                            );
                                        })}
                                    </Select>
                                </fieldset>
                            </Grid>
                            <Grid item xs={6}>
                                <fieldset>
                                    <legend style={{ fontSize: 'medium' }}>CECO</legend>
                                    <Select style={{ width: '95%' }}
                                        size='large'
                                        filterOption={filterOption}
                                        showSearch
                                        value={Number(infoCeco?.value) || 0}
                                    //onChange={(e) => setInfoClient(clients?.find(element => Number(element.value) === Number(e.target.value)))}
                                    >
                                        <Option value={0}><em>Seleccionar CECO</em></Option>
                                        {ceco?.map((opt) => {
                                            return (
                                                <Option key={'ceco' + opt.id} value={opt.id}>{opt.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </fieldset>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: '0.5rem' }}>
                                <fieldset>
                                    <legend style={{ fontSize: 'medium' }}>Nombre del Solicitante</legend>
                                    <Input
                                        type="text"
                                        style={{
                                            fontSize: 'medium',
                                            paddingTop: '0.5rem',
                                            width: '95%',
                                            color: "rgba(0, 0, 0, 0.65)",
                                            backgroundColor: "#ffffff",

                                        }}
                                        disabled={solicitante && Object.keys(solicitante).length !== 0}
                                        value={solicitante || ""}
                                    //onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
                                    />
                                </fieldset>
                            </Grid>
                            <Grid item xs={2} style={{ marginTop: '0.5rem' }}>
                                <fieldset>
                                    <legend style={{ fontSize: 'medium' }}>Celular</legend>
                                    <Input
                                        type="text"
                                        style={{
                                            fontSize: 'medium',
                                            paddingTop: '0.5rem',
                                            width: '95%',
                                            color: "rgba(0, 0, 0, 0.65)",
                                            backgroundColor: "#ffffff",

                                        }}
                                    // disabled={solicitante && Object.keys(solicitante).length !== 0}
                                    // value={solicitante || ""}
                                    //onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
                                    />
                                </fieldset>
                            </Grid>
                            <Grid item xs={4} style={{ marginTop: '0.5rem' }}>
                                <fieldset>
                                    <legend style={{ fontSize: 'medium' }}>Autorizado por</legend>
                                    <Select style={{ width: '95%' }}
                                        size='large'
                                        value={infoBoss?.value || 0}
                                        //onChange={(e) => setInfoBoss(boss?.find(element => Number(element.value) === Number(e.target.value)))}
                                        onChange={(value, option) => {
                                            setInfoBoss(option);
                                        }}
                                        filterOption={filterOption}
                                        showSearch
                                    >
                                        <Option value={0}><em>Seleccionar Jefe</em></Option>
                                        {boss?.map((opt) => {
                                            return (
                                                <Option key={'jef' + opt.id} value={opt.id}>{/*'nivel '+ opt.nivel + ' - ' +*/ opt.label}</Option>
                                            )
                                        })}
                                    </Select>
                                </fieldset>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: '0.5rem' }}>
                                <fieldset>
                                    <legend style={{ fontSize: 'medium' }}>Descripción del puesto</legend>
                                    <Input
                                        type="text"
                                        style={{
                                            marginTop: '0.1rem',
                                            paddingTop: '0.7rem',
                                            width: '95%',
                                            color: "rgba(0, 0, 0, 0.65)",
                                            backgroundColor: "#ffffff",

                                        }}
                                    //value={conocimiento?.detalle_mof?.respuesta || ""}
                                    //onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
                                    />
                                </fieldset>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: '0.5rem' }}>
                                <fieldset>
                                    <legend style={{ fontSize: 'medium' }}>Lugar de destino</legend>
                                    <Input
                                        type="text"
                                        style={{
                                            marginTop: '0.1rem',
                                            paddingTop: '0.7rem',
                                            width: '95%',
                                            color: "rgba(0, 0, 0, 0.65)",
                                            backgroundColor: "#ffffff",

                                        }}
                                    //value={conocimiento?.detalle_mof?.respuesta || ""}
                                    //onChange={(e) => handleUpdateDatosMof(24, e.target.value, "respuesta")}
                                    />
                                </fieldset>
                            </Grid>

                            <Grid item md={12} marginTop={'2rem'}>
                                <fieldset>
                                    <TextField
                                        id="outlined-number"
                                        placeholder="Escriba una descripción."
                                        variant="outlined"
                                        size="medium"
                                        label="Descripción del Pedido"
                                        multiline
                                        minRows={4}
                                        InputLabelProps={{ shrink: true, }}

                                        style={{ width: '98%' }}
                                        required
                                        name="motivo"
                                    //value={data.motivo || ''}
                                    //onChange={(e) => setData({ ...data, motivo: e.target.value })}
                                    />
                                </fieldset>
                            </Grid>

                            <Grid item md={12} marginTop={'2rem'}>
                                <fieldset>
                                    <TextField
                                        id="outlined-number"
                                        placeholder="Escriba o pegue una lista "
                                        variant="outlined"
                                        size="medium"
                                        label="Lista de Trabajadores"
                                        multiline
                                        minRows={4}
                                        InputLabelProps={{ shrink: true, }}

                                        style={{ width: '98%' }}
                                        required
                                        name="motivo"
                                    //value={data.motivo || ''}
                                    //onChange={(e) => setData({ ...data, motivo: e.target.value })}
                                    />
                                </fieldset>
                            </Grid>
                        </Grid>
                    </>
                </Grid>
            </Grid>

            <Box marginTop={'2rem'} sx={{ width: '100%', }}>
                <Tabs value={body} onChange={handleChangeBody} centered>
                    <Tab label="Laptops" />
                    <Tab label="Celulares" />
                    <Tab label="Relojes" />
                    <Tab label="Otros" />
                </Tabs>
            </Box>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
            >
                <Grid marginTop={'0.5rem'} item xs={12} sm={10} md={8} paddingLeft={'1rem'} paddingRight={'1rem'}>
                    {body === 0 && (
                        <>
                            <BodyLaptops

                            />
                        </>
                    )}
                    {body === 1 && (
                        <>
                            <BodyCelulares />
                        </>
                    )}
                    {body === 2 && (
                        <>
                            <BodyRelojes />

                        </>
                    )}
                    {body === 3 && (
                        <>
                            <BodyOtros />
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid marginTop={'2rem'} marginBottom={'2rem'} item xs={6} sm={2} md={2} display={'flex'} justifyContent={'center'}>
                <Box display={'flex'} justifyContent={'center'} mb={1}>
                    <MainButton
                        // onClick={handleOpen}
                        title="Registrar solicitud"
                        color="white"
                        background={theme.palette.primary.main}
                    />
                </Box>
            </Grid>
            <SpinnerLoader open={openModalLoader} />
        </Box>
    );
};

export default Requests;