import React, { useState, } from 'react';
import { Checkbox, FormControlLabel, InputAdornment, IconButton, Button, Select, MenuItem, Grid, Typography, FormControl, InputLabel, TextField,Box   } from '@mui/material';
import PersonService from '../../../services/person';
import AuthService from '../../../services/auth';
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
import { useTheme } from '@mui/material/styles';
import MainButton from '../general/MainButton';
import { useNavigate } from "react-router-dom";
import { Global } from "../../../utils/general";
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Datos = (props) => {
    const { infoPerson, setInfoPerson, setOpenModalLoader, userToken } = props;
    const theme = useTheme();
    const pathInit = Global.pathInit;
    const navigate = useNavigate();

    const [titleModalLoader, setTitleModalLoader] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [viewOldPassword, setViewOldPassword] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
    const [showForm, setShowForm] = useState(false);

    const id_usuarioLogin = infoPerson?.id_usuario;


    const onchange = async (e) => {
        let upData = Object.assign({}, infoPerson);
        upData.minutos = e.target.value;
        let body = { 'minutos': e.target.value };
        setInfoPerson(upData)
        await PersonService.updRecordatorioUser(upData.id_usuario, body)
            .then(result => {
                if (result.status === CODES.SUCCESS_200) {
                    Toast.fire({
                        icon: 'success',
                        title: result.data.message
                    })
                }
            })
            .catch(err => {
                console.log('getPersonByUser', err)
            })
    }

    const updPersonalInformation = async () => {
        try {
            setOpenModalLoader(true);
            const result = await PersonService.updPersonalInformation(infoPerson.id_persona, infoPerson)
            if (result.status === CODES.CREATE_201) {
                Toast.fire({
                    icon: 'success',
                    title: result?.data?.message
                })
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result?.response?.data?.message || result?.response?.data?.error || 'Error al actualizar!'
                })
            }
        } catch (err) {
            console.log('getPersonByUser', err)
        } finally {
            setOpenModalLoader(false);
        }
    }

    const cleanInfo = () => {
        setOldPassword('');
        setPassword('');
        setConfirmPassword('');
        setViewOldPassword(false);
        setViewPassword(false);
        setViewConfirmPassword(false);
        setErrorPassword('');
        setErrorConfirmPassword('');
    };

    const forceSignOut = async () => {
        let token = userToken?.token;
        let body = { Authorization: "Bearer " + token };
        setTimeout(
            AuthService.signOut(body).then((result) => {
                if (
                    result.status === CODES.BAD_TOKEN_498 ||
                    result.status === CODES.SUCCESS_200
                ) {
                    localStorage.removeItem("infoAssist");
                    navigate(`${(pathInit || '/')}`);
                    window.location.reload();
                }
            }),
            10000
        );
    };

    const handlePasswordChange = (value) => {
        // Actualizar el estado y mostrar mensajes de error si es necesario
        setPassword(value);

        // Validación básica
        const regex = /^(?=.*[@#$%&*])(?=.*\d)(?=.*[a-z])(?!.*[ \/]).{6,}$/;
        if (!regex.test(value)) {
            setErrorPassword('La contraseña debe tener al menos 6 caracteres, un número y un carácter especial.');
        } else {
            setErrorPassword('');
        }
    };

    const handleConfirmPasswordChange = (value) => {
        // Comparar con la contraseña
        if (value !== password) {
            setErrorConfirmPassword('Las contraseñas no coinciden.');
        } else {
            setErrorConfirmPassword('');
        }

        // Actualizar el estado
        setConfirmPassword(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password === oldPassword) {
            Toast.fire({
                icon: 'error',
                title: 'La nueva clave no debe ser igual a la anterior'
            })
        }
        if (password !== confirmPassword) {
            Toast.fire({
                icon: 'warning',
                title: 'Las clave y la confirmación no coinciden'
            })
        }

        let body = {
            id_usuario: id_usuarioLogin,
            oldClave: oldPassword,
            clave: password,
            clave_confirmation: confirmPassword
        };

        try {
            setTitleModalLoader('Actualizando');
            setOpenModalLoader(true);
            const result = await AuthService.updatePassword(body);

            if (result.status === CODES.SUCCESS_200) {
                Toast.fire({
                    icon: 'success',
                    title: result?.data?.message || 'Éxito en la actualización. Inicie sesión nuevamente'
                })
                cleanInfo();
                setTimeout(() => {
                    forceSignOut();  // Forzar cierre de sesión después del retraso
                }, 2000);
            } else {
                Toast.fire({
                    icon: 'success',
                    title: result?.response?.data?.message || 'Error en la actualización'
                })
            }

            return [];
        } catch (error) {
            console.log(`Error en actualizar contraseña: ${error}`);
        } finally {
            setTitleModalLoader(null);
            setOpenModalLoader(false);
        }
    };


    return (
        <div className='page-body-main'>
            <div className='page-body__table page-body__table--information'>
                <div className='page-body-table' style={{ paddingTop: '2rem' }}>
                    <Grid container pt={0} pr={0} pb={2} pl={2}>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Nombre:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {(infoPerson?.apePaterno || '') + ' ' +
                                    (infoPerson?.apeMaterno || '') + ', ' +
                                    (infoPerson?.nombres || '')}
                            </small></Typography>
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Telefono:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id="outlined-date"
                                variant="outlined"
                                size="small"
                                type='text'
                                name="telefono"
                                value={infoPerson?.telefono || ''}
                                onChange={(e) => setInfoPerson({ ...infoPerson, telefono: e.target.value })}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Correo:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <TextField
                                id="outlined-date"
                                variant="outlined"
                                size="small"
                                type='email'
                                name="email"
                                value={infoPerson?.email || ''}
                                onChange={(e) => setInfoPerson({ ...infoPerson, email: e.target.value })}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Sede:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {(infoPerson?.desEmpresa || '') + ' / ' +
                                    (infoPerson?.desCliente || '') + ' / ' +
                                    (infoPerson?.desUnidad || '') + ' / ' +
                                    (infoPerson?.desSede || '') + ' / ' +
                                    (infoPerson?.desArea || '')}
                            </small></Typography>
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Turno:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {infoPerson?.desTurno ?
                                    (infoPerson?.desTurno + ' (' + infoPerson?.ingreso?.substr(0, 5) + ' - ' + infoPerson?.salida?.substr(0, 5) + ')')
                                    : 'Sin turno asignado'
                                }
                            </small></Typography>
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Puesto:  </Typography>
                        </Grid>
                        <Grid item md={8}>
                            <Typography variant="h6" gutterBottom color="initial"><small className="text-muted">
                                {infoPerson?.desPuesto || ''}
                            </small></Typography>
                        </Grid>
                        <Grid item md={12} display={'flex'} justifyContent={'center'} mb={1}>
                            <MainButton
                                onClick={updPersonalInformation}
                                title="Actualizar datos"
                                color="white"
                                background={theme.palette.primary.main}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} pb={1}>
                            <Typography variant="h6" gutterBottom color="primary" className='font-information'>Alerta asistencia:</Typography>
                        </Grid>
                        <Grid item md={8}>
                            <FormControl variant="filled" sx={{ m: 1, width: 200 }}>
                                <InputLabel id="demo-simple-select-filled-label">Recordatorio</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={infoPerson?.minutos || ''}
                                    onChange={onchange}
                                    color={'secondary'}
                                >
                                    <MenuItem value=""><em>Seleccionar</em></MenuItem>
                                    <MenuItem value={60}>60 Min.</MenuItem>
                                    <MenuItem value={50}>50 Min.</MenuItem>
                                    <MenuItem value={40}>40 Min.</MenuItem>
                                    <MenuItem value={30}>30 Min.</MenuItem>
                                    <MenuItem value={20}>20 Min.</MenuItem>
                                    <MenuItem value={10}>10 Min.</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <div style={{marginTop:'2rem'}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showForm}
                                        onChange={(e) => setShowForm(e.target.checked)} // Cambia el estado para mostrar el formulario
                                        name="changePassword"
                                        color="primary"
                                    />
                                }
                                label="Cambiar contraseña"
                            />
                            {showForm && (
                               <Box border={1} borderRadius={2} padding={2} marginTop={2} borderColor="grey.300" width="100%"> 
                               {/* Caja con borde y ancho completo */}
                               <form onSubmit={handleSubmit}>
                                 <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                   
                                   <Grid item xs={12} md={10}>
                                     <TextField
                                       id="input-old-password"
                                       label="Ingrese contraseña anterior"
                                       placeholder="Contraseña anterior"
                                       InputProps={{
                                         startAdornment: (
                                           <InputAdornment position="start">
                                             <KeyIcon fontSize="small" />
                                           </InputAdornment>
                                         ),
                                         endAdornment: (
                                           <InputAdornment position="end">
                                             <IconButton
                                               aria-label="toggle visibility"
                                               size="small"
                                               onClick={() => setViewOldPassword(!viewOldPassword)}
                                             >
                                               {viewOldPassword ? <VisibilityIcon fontSize="inherit" /> : <VisibilityOffIcon fontSize="inherit" />}
                                             </IconButton>
                                           </InputAdornment>
                                         ),
                                       }}
                                       type={viewOldPassword ? "text" : "password"}
                                       variant="standard"
                                       fullWidth
                                       value={oldPassword}
                                       onChange={(e) => setOldPassword(e.target.value)}
                                     />
                                   </Grid>
                             
                                   <Grid item xs={12} md={10}>
                                     <TextField
                                       id="input-password"
                                       label="Ingrese nueva contraseña"
                                       placeholder="Nueva contraseña"
                                       InputProps={{
                                         startAdornment: (
                                           <InputAdornment position="start">
                                             {viewPassword ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                                           </InputAdornment>
                                         ),
                                         endAdornment: (
                                           <InputAdornment position="end">
                                             <IconButton
                                               aria-label="toggle visibility"
                                               size="small"
                                               onClick={() => setViewPassword(!viewPassword)}
                                             >
                                               {viewPassword ? <VisibilityIcon fontSize="inherit" /> : <VisibilityOffIcon fontSize="inherit" />}
                                             </IconButton>
                                           </InputAdornment>
                                         ),
                                       }}
                                       type={viewPassword ? "text" : "password"}
                                       variant="standard"
                                       fullWidth
                                       value={password}
                                       onChange={(e) => handlePasswordChange(e.target.value)}
                                       error={Boolean(errorPassword)}
                                       helperText={errorPassword}
                                     />
                                   </Grid>
                             
                                   <Grid item xs={12} md={10}>
                                     <TextField
                                       id="input-confirm-password"
                                       label="Confirme contraseña"
                                       placeholder="Confirme contraseña"
                                       InputProps={{
                                         startAdornment: (
                                           <InputAdornment position="start">
                                             {viewConfirmPassword ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                                           </InputAdornment>
                                         ),
                                         endAdornment: (
                                           <InputAdornment position="end">
                                             <IconButton
                                               aria-label="toggle visibility"
                                               size="small"
                                               onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                                             >
                                               {viewConfirmPassword ? <VisibilityIcon fontSize="inherit" /> : <VisibilityOffIcon fontSize="inherit" />}
                                             </IconButton>
                                           </InputAdornment>
                                         ),
                                       }}
                                       type={viewConfirmPassword ? "text" : "password"}
                                       variant="standard"
                                       fullWidth
                                       value={confirmPassword}
                                       onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                                       error={Boolean(errorConfirmPassword)}
                                       helperText={errorConfirmPassword}
                                     />
                                   </Grid>
                             
                                   <Grid item xs={12} md={10} style={{ textAlign: 'center', marginTop: '2rem' }}>
                                     <MainButton
                                       title="Guardar Cambios"
                                       type="submit"
                                       variant="contained"
                                       color="primary"
                                       background={theme.palette.primary.main}
                                     />
                                   </Grid>
                             
                                 </Grid>
                               </form>
                             </Box>
                            )}
                        </div>

                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default Datos;